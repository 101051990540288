///
/// Header.jsx
///

function Header() {
  const header = (
    <header className="Main-header">
      <p>
        Lakebolt Research proudly presents ...
      </p>
    </header>
  );
  return header;
}

export default Header;
