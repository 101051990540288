///
/// Body.jsx
///

import logo from "./logo.svg";
import { BrowserRouter } from "react-router-dom";

function Body() {
  const body = (
    <body className="Main-body">
      <BrowserRouter>
        <h1>
          <b>MIT</b>iCalc
        </h1>
        <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          <img src={logo} className="Main-logo" alt="logo" />
        </a>
      </BrowserRouter>
    </body>
  );
  return body;
}

export default Body;
