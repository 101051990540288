///
/// Main.jsx
///

import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

import "./Main.css";

function Main() {
  return (
    <div className="Main">
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default Main;
